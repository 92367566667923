import React, { useState } from "react";
import { HiX } from "react-icons/hi"; // Importing the HiIcon cross from react-icons
import { usePopUp } from "../../hooks/usePopUp";
import { useNotification } from "../../hooks/useNotification";
import { addDocument } from "../../firebase/firestore";
import { emailTemplate } from "./Help_Email";
import useAuth from "../../hooks/useAuth";
import { NotificationTypes } from "../../utils/notifications";

export default function HelpPopUp({ onClose }) {
    const { showNotification } = useNotification();
    const { isPopUpVisible } = usePopUp();
    const [selectedHelp, setSelectedHelp] = useState(null);
    const [emailContent, setEmailContent] = useState("");
    const { user } = useAuth();

    const handleSendEmail = async (event) => {
        event.preventDefault();

        if (!selectedHelp || !emailContent) {
            showNotification(NotificationTypes.DANGER, "Please complete all fields.");
            return;
        }

        const htmlContent = emailTemplate(`${user.firstName} ${user.lastName}`, emailContent);


        const emailData = {
            to: [
                { email: "milan@algointeractive.com", name: "Support 1" },
                { email: "Info@tactilevr.com", name: "Support 2" },
                { email: "info@algointeractive.com", name: "Support 3" },
            ],
            from: { email: "info@coursetavr.com", name: "Courseta Helpdesk" },
            subject: selectedHelp || "No Subject",
            html: htmlContent || "",
        };

        try {
            await addDocument(emailData, "emails");
            showNotification(NotificationTypes.SUCCESS, "Email sent successfully!");
            onClose();
        } catch (error) {
            console.error("Error sending email:", error);
            showNotification(NotificationTypes.DANGER, "Failed to send email.");
        }
    };

    const handleHelpChoice = (choice) => setSelectedHelp(choice);
    const handleBackClick = () => setSelectedHelp(null);
    const handleEmailContentChange = (e) => setEmailContent(e.target.value);

    // if (!isPopUpVisible) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            {/* Outline div with HiX cross for close */}
            <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-lg max-w-md w-full p-6">
                <button
                    onClick={onClose}
                    className="absolute top-3 right-3 p-2 rounded-full text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
                >
                    <HiX className="w-6 h-6" />
                </button>
                {selectedHelp ? (
                    <div>
                        <button
                            onClick={handleBackClick}
                            className="text-blue-600 hover:text-blue-800 dark:hover:text-blue-400 mb-4"
                        >
                            ← Back
                        </button>
                        <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                            {selectedHelp}
                        </h3>
                        <form className="space-y-6 mt-4" onSubmit={handleSendEmail}>
                            <textarea
                                className="block w-full p-2.5 text-sm bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                rows="4"
                                placeholder="Type your question here..."
                                value={emailContent}
                                onChange={handleEmailContentChange}
                            ></textarea>
                            <div className="flex justify-end">
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                                >
                                    Send
                                </button>
                            </div>
                        </form>
                    </div>
                ) : (
                    <div>
                        <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                            How can we help you?
                        </h3>
                        <div className="mt-4 space-y-2">
                            {[
                                "General questions",
                                "Technical support",
                                "Billing and payments",
                                "Feedback and suggestions",
                            ].map((item, index) => (
                                <button
                                    key={index}
                                    onClick={() => handleHelpChoice(item)}
                                    className="w-full text-left p-2 border rounded-md text-sm text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700"
                                >
                                    {item}
                                </button>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
